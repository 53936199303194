import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Ribbon from 'components/elements/Vectors/Ribbon'

// Images
import BG from 'img/background.svg'

// Interface
import { BannerProps } from './BannerShell'

const Section = styled.section`
  @media (min-width: 992px) {
    height: 80vh;
    min-height: 1000px;
  }
`

const BannerWrapper = styled.div`
  z-index: 6;
  padding-top: 7.5rem;
  padding-bottom: 6.75rem;
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    color: ${(props) => props.theme.color.light};
  }

  & h1 {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      height: 2px;
      background-color: ${({ theme }) => theme.color.secondary};

      @media (min-width: 992px) {
        bottom: -20px;
        left: -1500px;
        width: 2000px;
      }
    }
  }

  & h2 {
    letter-spacing: 0.2em;
    opacity: 0.7;
  }

  @media (min-width: 992px) {
    & h1 {
      font-size: ${({ theme }) => theme.font.size.ultra};
      line-height: ${({ theme }) => theme.font.size.giga};
      margin-bottom: 3rem;
    }

    & h2 {
      font-size: ${({ theme }) => theme.font.size.large};
      line-height: ${({ theme }) => theme.font.size.huge};
    }
  }

  @media (max-width: 991.98px) {
    & h2 {
      font-size: ${({ theme }) => theme.font.size.big};
      line-height: ${({ theme }) => theme.font.size.bigger};
      margin-bottom: 1rem;
    }
  }
`

const SVGWrapper = styled.div`
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  max-width: 1440px;

  & svg {
    width: 100% !important;
  }

  @media (max-width: 991.98px) {
    top: -100px;
  }
`

const Square = styled.div`
  background-color: #f3f3f3;
  right: 0;
  z-index: 2;
  @media (min-width: 1200px) and (max-width: 1441.98px) {
    width: 250px;
    height: 250px;
    bottom: 30%;
  }

  @media (max-width: 1199.98px) {
    width: 150px;
    height: 150px;
  }

  @media (max-width: 575.98px) {
    width: 90px;
    height: 90px;
  }
`

const BGColor = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  @media (min-width: 992px) {
    height: 60%;
  }
`

const Shadow = styled.div`
  filter: drop-shadow(1px 1px 75px rgba(0, 0, 0, 0.95));
  background-color: #f3f3f3;
  height: 100px;
  transform: skewY(-11deg);
  z-index: 4;

  @media (min-width: 1520px) {
    display: none;
  }

  @media (min-width: 1400px) {
    bottom: 17%;
  }

  @media (max-width: 1399.98px) {
    bottom: 21%;
  }

  @media (max-width: 1199.98px) {
    bottom: 27%;
  }

  @media (max-width: 991.98px) {
    display: none;
  }
`

const BannerDefault: React.FC<BannerProps> = ({ fields }) => {
  return (
    <Section className="position-relative">
      <div className="container">
        <Shadow className="position-absolute w-75" />
      </div>
      <SVGWrapper className="position-absolute mx-auto">
        <Ribbon image={fields.image} />
      </SVGWrapper>

      <BannerWrapper className="position-relative">
        <div className="container py-5">
          <Content content={fields.description} className="position-relative" />
        </div>
      </BannerWrapper>
      <BGColor className="position-absolute" />
      <Square className="position-absolute" />
    </Section>
  )
}

export default BannerDefault
