import React from 'react'

interface RibbonProps {
  image: any
}

const Ribbon: React.FC<RibbonProps> = ({ image }) => {
  if (!image.localFile || !image.localFile.publicURL) return null

  return (
  <svg
    width="1440"
    height="976"
    viewBox="0 0 1440 976"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-149 554.219C423.86 525.374 659.501 483.517 1039.43 308.745C1222.69 224.443 1367.92 99.5387 1459.58 -3H1463V396.31L1388.63 677.717C1351.5 696.5 1263.5 734 1102.92 792.773C909.44 863.59 500.377 935.326 -75 975C-79.9589 975.342 -84.894 975.675 -89.8052 976H-149V554.219Z"
      fill="url(#bannerlanding)"
    />
    <defs>
      <pattern
        id="bannerlanding"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use
          xlinkHref="#image0_138_11"
          transform="translate(0 -0.0487254) scale(0.000244141 0.000401997)"
        />
      </pattern>
      <image
        id="image0_138_11"
        width="4096"
        height="2730"
        xlinkHref={image.localFile.publicURL}
      />
    </defs>
  </svg>
)}

export default Ribbon
